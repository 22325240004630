.accordion-button[aria-expanded="true"] {
    background-color:#bccee8;
    color: #000000;
    border: none;
}

.customerList {
    width: 100%;
    height: 100%;
    display: flex;
}

.accordion-button::after {
    color: #e9e9e9;
}

.accordion-button:hover {
    background-color: #e9e9e9;
}




.adminTransactionsLoadingSpinner {
    width: 100%;
    height: 200px;;
    display: flex;
    justify-content: center;
    align-items: center

}

