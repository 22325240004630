/* main {
  width: 100%;
  padding: 0% 0% 10% 0%;
}  */

@media print {
  .layoutSidebar {
    display: none;
  }
}
