.accordion-button[aria-expanded="true"] {
    background-color:#bccee8;
    color: #000000;
    border: none;
}

.resourceListContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: 80px 30px 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
}

.accordion-button::after {
    color: #e9e9e9;
}

.accordion-button:hover {
    background-color: #e9e9e9;
}

.transactionsHeader {
    grid-row: 1;
    grid-column: 1 / 4;
    display: flex;
    justify-content: center;
    align-items: center;
}
.transactionsTopbar { 
grid-row: 2;
grid-column: 1 / 4;
display: flex;
justify-content: center;
}


.resourceList {
    grid-column: 1 / 4;
    grid-row: 3;
    margin: 0px 0px 0px 0px;
}

.loadingSpinner {
    margin: 0 auto;
    grid-row: 2;
    grid-column: 1 / 4;
}

.transactionsPagination {
    grid-row: 6; 
    grid-column: 1 / 4;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

.loadingSpinnerTransactions {
    margin: 0 auto;
    grid-row: 4;
    grid-column: 1 / 4;
}

.noTransactionsToView {
    grid-row: 4;
    grid-column: 1 / 4; 
    text-align: center;
    color:#717171;
    font-size: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.transactionsHiddenPagination {
    grid-row: 6; 
    grid-column: 1 / 4;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 20px;
}