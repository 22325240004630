.customersList {
  width: 100%;
  display: flex;
  justify-content: center;
}

.allCustomersHeader {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.customerListLoadingSpinner {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  place-self: center;
}

.customersPagination {
  grid-row: 6;
  grid-column: 1 / 4;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
}
