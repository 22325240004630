.loginLayoutContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr repeat(4, 1fr) 1fr;
    grid-template-rows: 0.1fr repeat(10, 0.1fr) auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  
  .loginLayoutLeft {
    grid-area: 2 / 1 / 6 / 2;
  
  }
  .loginLayoutHeader {
    grid-area: 1 / 1 / 2 / 8;
    z-index: 1500;
  
  }
  .mainLogin {
    grid-area: 2 / 3 / 7 / 5;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
  }
  .loginLayoutRight {
    grid-area: 2 / 5 / 6 / 7;
  
  }

  .loginErrorContainer {
    margin-top: 0;
  }
  
  button:hover {
      background-color: #484848;
  }


@media screen and (max-width: 1400px) {
  .mainLogin {
    grid-area: 2 / 2 / 7 / 6;
  }
}

@media screen and (max-width: 750px) {
  .mainLogin {
    grid-area: 2 / 1 / 8 / 7;
    margin-left: 20px;
  }
}

@media screen and (max-height: 1300px) {
  .mainLogin {
    grid-area: 2 / 1 / 10 / 7;
    margin-left: 20px;
  }
}