.trasactionFormHeader {
    display: flex;
    justify-content: center;
    margin-top: 2%;
}

.transactionFormLoadingSpinner {
    width: 100%;
    height: 200px;;
    display: flex;
    justify-content: center;
    align-items: center
}