.adminSearchFields {
    display: flex;
    justify-content: center;
}

.adminTransactionsSearchLoadingSpinner {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

}