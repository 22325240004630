.dashboardContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr) auto;
  grid-template-rows: repeat(2, 200px);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding: 30px 0px 30px 30px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  padding: 10px;
  background: rgb(11,11,11);
  background: linear-gradient(0deg, rgba(11,11,11,1) 0%, rgba(7,0,36,1) 28%);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  transition: 0.3s;
  cursor: pointer;

}
.div1 a {
  text-decoration: none;
}

.div1 a:hover {
  text-decoration: none;
}

.div1:hover {
  background: rgb(22,38,59);
  background: linear-gradient(0deg, rgba(22,38,59,1) 0%, rgba(26,28,69,1) 2%, rgba(13,10,59,1) 4%, rgba(9,4,31,1) 25%);
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  padding: 10px;
  background: rgb(11,11,11);
  background: linear-gradient(0deg, rgba(11,11,11,1) 0%, rgba(7,0,36,1) 28%);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  transition: 0.3s;
  cursor: pointer;

}

.div2 a {
  text-decoration: none;
}

.div2:hover {
  background: rgb(22,38,59);
  background: linear-gradient(0deg, rgba(22,38,59,1) 0%, rgba(26,28,69,1) 2%, rgba(13,10,59,1) 4%, rgba(9,4,31,1) 25%);
  }
.div3 {
  grid-area: 2 / 1 / 3 / 2;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  padding: 10px;
  background: rgb(11,11,11);
  background: linear-gradient(0deg, rgba(11,11,11,1) 0%, rgba(7,0,36,1) 28%);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  transition: 0.3s;
  cursor: pointer;
}

.div3 a {
  text-decoration: none;
}

.div3:hover {
  background: rgb(22,38,59);
  background: linear-gradient(0deg, rgba(22,38,59,1) 0%, rgba(26,28,69,1) 2%, rgba(13,10,59,1) 4%, rgba(9,4,31,1) 25%);
}
.div4 {
  grid-area: 2 / 2 / 3 / 3;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  padding: 10px;
  background: rgb(11,11,11);
  background: linear-gradient(0deg, rgba(11,11,11,1) 0%, rgba(7,0,36,1) 28%);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  transition: 2s;
  cursor: pointer;
}

.div4 a {
  text-decoration: none;
}

.div4:hover {
  background: rgb(22,38,59);
  background: linear-gradient(0deg, rgba(22,38,59,1) 0%, rgba(26,28,69,1) 2%, rgba(13,10,59,1) 4%, rgba(9,4,31,1) 25%);
}

@media screen and (max-width: 530px) {

    .dashboardContainer {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 200px);
        grid-column-gap: 30px;
        grid-row-gap: 10px;
        padding: 30px 30px 30px 30px;
      }

      .div1 {
          grid-row: 1;
          grid-column: 1 / 3;

      }

      .div2 {
        grid-row: 2;
        grid-column: 1 / 3;

    }

    .div3 {
        grid-row: 3;
        grid-column: 1 / 3;

    }

    .div4 {
      grid-row: 4;
      grid-column: 1 / 3;

  }

}