.topBar {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.topbarHomeButton {
  text-decoration: none;
  color: #ffffff;
}

.topbarHomeButton:hover {
  color: #ffffff;
}

.topbarHomeButtonMobile {
  text-decoration: none;
  color: #ffffff;
}

.topbarHomeButtonMobile:hover {
  color: #ffffff;
}
